import "../../style.css";
import React, {useCallback, useState} from "react";
import step_prev from "../../../../../../assets/images/icons/step_previous_icon.png";
import {useNavigate} from "react-router-dom";
import config from "../../../../../../config";
import axios from "axios";

export default function Information({changeStep,data,setData,closeQuizModal}){
    let navigate=useNavigate()
    let [information,setInformation]=useState({
        firstName: "",
        lastName:"",
        email: "",
    })
    const [error, setError] = useState("")

    const addValue = useCallback(info => {
        data.stepSeven = info
        setData({...data})
    }, [data])

    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(information.email).toLowerCase());
    }

    const handleChangeInput = (e, name) => {
        setInformation({...information, [name]: e})
        setError("")
        addValue(information)
    }

    let handleFindHome =()=>{
        let values = {
            firstName: information.firstName,
            lastName: information.lastName,
            email: information.email,
        }
        // if (information.email.length && information.firstName.length && information.lastName.length) {
        //     if (information.firstName.length> 2 ) {
        //         if (validateEmail()) {
        //             axios.post(`${config.url}/`, values)
        //                 .then(response => {
        //                     console.log(response, "response");
        //                     localStorage.setItem('jwtToken', response.data.token)
        //                     addValue(response.data.message)
        //                     closeQuizModal()
        //
        //                 })
        //                 .catch(error => {
        //                     console.log(error.response, "error")
        //                     setError(error.response?.data.error)
        //                 })
        //         } else {
        //             setError("Incorrect email address")
        //         }
        //     } else {
        //         setError("Name is incorrect")
        //     }
        // }
        localStorage.setItem('filterData', JSON.stringify(data))
        navigate("/listings")
        closeQuizModal()

    }
    console.log(data, "addValue Data")



    return(
        <div className="d_flex quiz_modal_block ">
            <div className="d_flex fd_column quiz_modal_block1">
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">1</span>
                    Home Type
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18  active">2</span>
                    Bedrooms
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">3</span>
                    Bathrooms
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">4</span>
                    Acreage
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">5</span>
                    Price
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">6</span>
                    Town
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">7</span>
                    Your Information
                </div>
            </div>
            <div className="d_flex fd_column space_between quiz_modal_block2">
                <div>
                    <h2 className="f_600">Enter your information</h2>
                    <p className="info">Be the first to know when the perfect property hits the market</p>
                    <div className="d_flex fd_column quiz_modal_block2_items">

                        <div className="d_flex align_items_center quiz_modal_block2_item information_input_b">
                            <input type="text" className="information_input" placeholder="First Name"
                                   onChange={(e,index)=>handleChangeInput(e.target.value,'firstName')}
                            />
                        </div>
                        <div className="d_flex align_items_center quiz_modal_block2_item margin_top_24 information_input_b">
                            <input type="text" className="information_input" placeholder="Last Name"
                                   onChange={(e,index)=>handleChangeInput(e.target.value, "lastName")}
                            />
                        </div>
                        <div className="d_flex align_items_center quiz_modal_block2_item margin_top_24 information_input_b">
                            <input type="email" className="information_input" placeholder="Email"
                                   onChange={(e,index)=>handleChangeInput(e.target.value,"email")}
                            />
                        </div>
                    </div>
                </div>
                {error ? <p className="error">{error}</p> : null}
                <div className="d_flex next_b align_items_center">
                    <div className="previous_b d_flex align_items_center" onClick={() => changeStep('back')}>
                        <img src={step_prev} alt="prev"/>
                    </div>
                    <div className="d_flex justify_content_start align_items_center " onClick={handleFindHome}>
                        <button className="c_white bc_blue fs_18 f_600">Find Home</button>
                    </div>
                </div>

            </div>
        </div>
    )
}