import "./style.css";
import Header from "../../componets/header/header";
import Footer from "../../componets/footer/footer";
import ListingSearch from "./components/listing_search/ListingSearch";
import SearchBlock from "./components/search_block/SearchBlock";




export default function Listings(){
    return (
        <div className="main">
            <div>
                <Header/>
                <div className="container">
                    <ListingSearch/>
                    <SearchBlock/>
                </div>

            </div>
            <div className="margin_top_160">
                <Footer/>
            </div>
        </div>
    )
}