import "./style.css";
import fullName_icon from "../../../../assets/images/icons/CA_fullName_icon.png";
import phone_number_icon from "../../../../assets/images/icons/CA_phone_icon1.svg.png";
import message_icon from "../../../../assets/images/icons/CA_Chat_Circle_icon.png";
import CA_agent_pic from "../../../../assets/images/contact_agent_pic.png";

export default function ContactAgent(){
    return (
        <div className="d_flex fd_column flat_desc_cont_b2">
            <p className="fs_14 c_white text_left price_title">PRICE</p>
            <p className="c_white f_600 text_left price">$599,000</p>
            <div className="flat_desc_cont_b2_content margin_top_24">
                <p className="c_white fs_20 f_500 margin_top_24 text_left">Contact Agent</p>
                <div className="margin_top_24 flat_desc_cont_b2_content_input_b">
                    <input type="text" placeholder="Full name" className="fs_18"/>
                    <img src={fullName_icon} alt="user_icon"/>
                </div>
                <div className="margin_top_16 flat_desc_cont_b2_content_input_b">
                    <input type="text" placeholder="Phone number" className="fs_18"/>
                    <img src={phone_number_icon} alt="phone_icon"/>
                </div>
                <div className="margin_top_16 flat_desc_cont_b2_content_input_b">
                    <textarea name="" id="" cols="" rows="5" className="fs_18">Message</textarea>
                    <img src={message_icon} alt="message_icon" className="message_icon"/>
                </div>
                <button className="c_white fs_18 bc_blue margin_top_16">Contact</button>
            </div>
            <div className="margin_top_48 CA_info_b d_flex fd_column align_items_center">
                <img src={CA_agent_pic} alt="agent_pic" className="contact_agent_pic"/>
                <p className="margin_top_12 c_white fs_20">Chelsea Smith</p>
                <span className="fs_16">Agent</span>
            </div>

        </div>
    )
}