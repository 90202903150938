import "./style.css";
import {useNavigate} from "react-router-dom";
import Header from "../../componets/header/header";
import Footer from "../../componets/footer/footer";
import home_main_pic from "../../assets/images/home_back_pic.svg";
import lamp_icon from "../../assets/images/icons/Lamp_icon.png";
import exclusive_icon from "../../assets/images/icons/exclusive_icon.svg";
import obligation_icon from "../../assets/images/icons/no_bligation_icon.svg";
import time_icon from "../../assets/images/icons/247_icon.svg";
import secure_icon from "../../assets/images/icons/secure_icon.svg";
import Categories from "./components/categories/Categories";
import HowWorks from "./components/how_works/HowWorks";
import Explore from "./components/explore/Explore";
import WhyChooseUs from "./components/why_choose/WhyChooseUs";
import {useState} from "react";
import ModalQuiz from "./components/modal_quiz/ModalQuiz";

export default function Home() {
    let navigate = useNavigate()
    const [modalQuizOpen, setModalQuizOpen] = useState(false);

    function openQuizModal() {
        setModalQuizOpen(true);
    }

    function closeQuizModal() {
        setModalQuizOpen(false);
        console.log("rwrwr")
    }

    return (
        <div className="main">
            <div>
                <Header/>
                <div className="home_container_1">
                    <img src={home_main_pic} alt="home_main_pic"/>
                    <div className="text_block d_flex fd_column justify_content_start">
                        <p className="f_700 text_left">Find your home</p>
                        <h1 className="text_left f_700 c_white">Take a
                            quick quiz</h1>
                        <div className="text_left text">
                            Be the first to know when a new listing hits the market in your neighborhood! Click Here To
                            Enter The Details Of Your Dream House Wish List Here!
                        </div>
                        <div className="d_flex justify_content_start align_items_center take_quiz_b" onClick={openQuizModal}>
                            <button className="d_flex align_items_center take_quiz_btn fs_18 f_600"><img src={lamp_icon}
                                                                                                         alt="lamp_icon"/>Take
                                A Quiz
                            </button>
                        </div>
                <ModalQuiz modalQuizOpen={modalQuizOpen} setModalQuizOpen={setModalQuizOpen} openQuizModal={openQuizModal} closeQuizModal={closeQuizModal}/>
                    </div>
                </div>
                <div className="info_b">
                    <div className="container d_grid grid_columns_4fr">
                        <div className="d_flex fd_column align_items_center justify_content_center">
                            <img src={exclusive_icon} alt="exclusive_icon"/>
                            <p>Exclusive Offers</p>
                        </div>
                        <div className="d_flex fd_column align_items_center justify_content_center">
                            <img src={obligation_icon} alt="obligation_icon"/>
                            <p>No Obligation</p>
                        </div>
                        <div className="d_flex fd_column align_items_center justify_content_center">
                            <img src={time_icon} alt="time_icon"/>
                            <p>24/7 Accessibility</p>
                        </div>
                        <div className="d_flex fd_column align_items_center justify_content_center">
                            <img src={secure_icon} alt="secure_icon"/>
                            <p>Secure and Private</p>
                        </div>
                    </div>
                </div>
                <Categories/>
                <HowWorks/>
                <Explore/>
                <WhyChooseUs/>

            </div>
            <div className="margin_top_160">
                <Footer/>
            </div>
        </div>
    )
}