import "./style.css";
import search_icon from "../../../../assets/images/icons/search_icon.png";

export default function ListingSearch() {
    return (
        <div className="listing_search_cont d_flex space_between">
            <div className="d_flex align_items_center listing_search_cont_search_b">
                <div style={{position:"relative"}}>
                    <input type="text" placeholder="Where?"/>
                    <img src={search_icon} alt="search_icon"/>
                </div>
                <button className="bc_blue c_white f_600 fs_18">Search</button>
            </div>
            <div className="listing_search_cont_save_search_b">
                <button className="fs_18 f_600">Save Search</button>
            </div>

        </div>
    )
}