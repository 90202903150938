import "./style.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {Typography} from "@mui/material";

export default function PaginationComponent({found,setFound, page,setPage,handleChange}){
    console.log(found.length,"foundLength")

    return (
        <Stack spacing={2} className="pagination_cont">
            <Pagination
                count={14}
                page={page}
                onChange={handleChange}
            />
        </Stack>
    )
}