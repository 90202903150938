import "./style.css";
import ExploreSlider from "./ExploreSlider";

export default function Explore(){
    return (
        <div className="explore_container margin_top_160">
            <div className="container">
                <h2 className="text_left">Explore Our Properties</h2>
                <div className="text_left margin_top_16 desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua.</div>
            </div>
            <ExploreSlider/>
            <div className="d_flex justify_content_center align_items_center view_all_btn_b">
                <button className="view_all_btn fs_18 f_600">View All</button>
            </div>
        </div>
    )
}