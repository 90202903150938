import "./style.css";
import {NavLink, useNavigate} from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import {useEffect, useRef, useState} from "react";
import user_pic from "../../assets/images/user_pic_circle.png";
import arrow_down from "../../assets/images/icons/down_icon.png";
import saved_listings from "../../assets/images/icons/saved_listings_icon.png";
import dropdown_user_icon from "../../assets/images/icons/dropdown_user_icon.png";
import lock_icon from "../../assets/images/icons/Lock_icon.png";
import exit_icon from "../../assets/images/icons/Exit_icon.png";

export default function Header() {
    let navigate = useNavigate()
    let [token, setToken] = useState(localStorage.getItem("jwtToken") || "");
    let [showUserDropdown, setShowUserDropDown] = useState(false)
    let closeHeaderDropDContentRef=useRef(null)
    let closeHeaderLoginDropDRef=useRef(null)

    const handleClickOutSide = (e) => {
        let contentLoginRef =  closeHeaderLoginDropDRef;
        let closeLoginDropDContentRef=closeHeaderDropDContentRef

        if (closeLoginDropDContentRef.current && !closeLoginDropDContentRef.current.contains(e.target) && !contentLoginRef.current.contains(e.target)) {
            setShowUserDropDown(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutSide, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide, true)
        }
    }, [])

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);


    let handleLogOut = ()=>{
        localStorage.removeItem("jwtToken")
        navigate("/sign_in")

    }

    return (
        <header className="bc-white header_login_container">
            <div className="container">
                <div className="d_flex align_items_center space_between">
                    <div onClick={() => navigate("/")}><img src={logo} alt="logo" className="logo"/></div>
                    <div className="d_flex align_items_center space_between header_login_content">
                        <NavLink
                            className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn'}
                            to="/listings">Listings</NavLink>
                        <NavLink
                            className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn'}
                            to="/how_works">How it works</NavLink>
                        <NavLink
                            className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn'}
                            to="/why_choose">Why choose Homechirp</NavLink>
                        <NavLink
                            className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn'}
                            to="/why_choose">Contact us</NavLink>
                    </div>
                    {!token ?
                        <div className="d_flex align_items_center space_between">
                            <button className="f_600 fs_18 sign_in_btn"
                                    onClick={() => navigate("/sign_in")}>Sign in
                            </button>
                            <div className="sign_up_btn">
                                <button className="f_600 fs_18"
                                        onClick={() => navigate("/sign_up")}>Sign up
                                </button>
                            </div>
                        </div>
                        :
                        <div className="d_flex align_items_center space_between user_login_cont"
                             onClick={() => setShowUserDropDown(!showUserDropdown)} ref={closeHeaderLoginDropDRef}>
                            <img src={user_pic} alt="user_pic" className="user_pic"/>
                            <p className="fs_16 f_500">John Doe</p>
                            {showUserDropdown ?
                                <img src={arrow_down} alt="arrow_down"/>
                                :
                                <img src={arrow_down} alt="arrow_down" style={{transform: "rotate(188deg)"}}/>
                            }
                            {
                                showUserDropdown &&
                                <div className="header_dropdown d_flex fd_column" ref={closeHeaderDropDContentRef}>
                                    <div className="d_flex align_items_center dropdown_item" onClick={()=>navigate("")}>
                                        <img src={saved_listings}  alt="icon"/>
                                        Saved Listings
                                    </div>
                                    <div className="d_flex align_items_center dropdown_item" onClick={()=>navigate("")}>
                                        <img src={dropdown_user_icon} alt="icon"/>
                                        Edit Profile
                                    </div>
                                    <div className="d_flex align_items_center dropdown_item" onClick={()=>navigate("")}>
                                        <img src={lock_icon} alt="icon"/>
                                        Change Password
                                    </div>
                                    <div className="d_flex align_items_center exit_btn_b margin_top_16" onClick={handleLogOut}>
                                        <img src={exit_icon} alt="icon"/>
                                        <button>Log out</button>
                                    </div>
                                   

                                </div>
                            }

                        </div>
                    }
                </div>
            </div>
        </header>
    )
}