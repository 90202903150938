import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import "./acreage/style.css";





export default function RangeSlider({valueText, value,handleChange,setValue,valueRange}) {

    return (
        <Box>
            <Slider
                getAriaLabel={() => 'range'}
                value={value}
                min={valueRange[0]}
                step={1}
                max={valueRange[1]}
                scale={valueText}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valueText}
                className="range_slider"
            />
        </Box>
    );
}