import "../../style.css";
import React, {useCallback, useState} from "react";
import step_prev from "../../../../../../assets/images/icons/step_previous_icon.png";
import RangeSlider from "../RangeSlider";

export default function Price({changeStep,data,setData}){
    let valueRange= [0, 25]
    const [value, setValue] = useState(valueRange);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(25);

    const addValue = useCallback(value => {
        data.stepFive= value
        setData({...data})
    }, [data])

    const handleChange = (event, newValue) => {
        setMin(newValue[0])
        setMax(newValue[1])
        setValue(newValue);
        addValue(newValue)
    };
    function valueText(value) {
        return `$ ${value}`;
    }
    const changeInput = (e, val) => {
        if(e >= 0 && e <= 20){
            if(val === 'min'){
                setMin(e)
                value[0] = e
                setValue(value)
                addValue(value)
                console.log(value[0],"val")
            }
            if(val === 'max'){
                setMax(e)
                value[1] = e
                setValue(value)
                addValue(value)
            }
        }
    }

    return(
        <div className="d_flex quiz_modal_block ">
            <div className="d_flex fd_column quiz_modal_block1">
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">1</span>
                    Home Type
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18  active">2</span>
                    Bedrooms
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">3</span>
                    Bathrooms
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">4</span>
                    Acreage
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">5</span>
                    Price
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">6</span>
                    Town
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">7</span>
                    Your Information
                </div>
            </div>
            <div className="d_flex fd_column space_between quiz_modal_block2">
                <div>
                    <h2 className="f_600">Price Range</h2>
                    <div className="d_flex fd_column quiz_modal_block2_items">
                        <div className="d_flex align_items_center ranger_input_b">
                            <div className="d_flex align_items_center ">
                                <input type="text"
                                       className=""
                                       placeholder="Min $."
                                       min={valueRange[0]}
                                       max={valueRange[1]}
                                       value={min}
                                    //onChange={(e)=>addValue(value)}
                                       onChange={e => changeInput(e.target.value, 'min')}

                                />
                            </div>
                            <div className="d_flex align_items_center">
                                <input type="text" className=""
                                       placeholder="Max $."
                                       min={valueRange[0]}
                                       max={valueRange[1]}
                                       value={max}
                                    //onChange={(e)=>addValue(value)}
                                       onChange={e => changeInput(e.target.value, 'max')}
                                />
                            </div>
                        </div>
                        <RangeSlider value={value} setValue={setValue} handleChange={handleChange} valueText={valueText} valueRange={valueRange}/>
                    </div>
                </div>
                <div className="d_flex next_b align_items_center">
                    <div className="previous_b d_flex align_items_center" onClick={() => changeStep('back')}>
                        <img src={step_prev} alt="prev"/>
                    </div>
                    <div className="d_flex justify_content_start align_items_center " onClick={() => changeStep('next')}>
                        <button className="c_white bc_blue fs_18 f_600">Next <span> > </span></button>
                    </div>
                </div>

            </div>
        </div>
    )
}