import "./style.css";

export default function WhyChooseUs() {
    return (
        <div className="why_choose_container margin_top_160">
            <div className="container d_flex space_between align_items_start">
                <div className="why_choose_b1 d_grid grid_gab_24 grid_columns_2fr">
                    <div className="d_flex fd_column justify_content_start why_choose_b1_item">
                        <div className="number_circle bc_blue">
                            1
                        </div>
                        <p className="margin_top_16 fs_18 f_600 text_left">Stay Informed</p>
                        <p className="fs_18 f_600 text_left">About New Listings</p>
                        <div className="margin_top_16 text_left desc">
                            Never miss a great opportunity! Sign up for HomeChirp and get instant alerts for new real
                            estate listings in your desired areas.
                        </div>
                    </div>
                    <div className="d_flex fd_column justify_content_start why_choose_b1_item">
                        <div className="number_circle bc_darkBlue">
                            2
                        </div>
                        <p className="margin_top_16 fs_18 f_600 text_left">Personalized Property Alerts</p>
                        <div className="margin_top_16 text_left desc">
                            Receive property alerts tailored to your preferences. We help you find your dream home with
                            listings that match your criteria.
                        </div>
                    </div>
                    <div className="d_flex fd_column justify_content_start why_choose_b1_item">
                        <div className="number_circle bc_darkBlue">
                            3
                        </div>
                        <p className="margin_top_16 fs_18 f_600 text_left">Save Time and Effort</p>
                        <div className="margin_top_16 text_left desc">
                            No more endless searching. Let HomeChirp do the work for you. Sign up, sit back, and let
                            your dream home come to you.
                        </div>
                    </div>
                    <div className="d_flex fd_column justify_content_start why_choose_b1_item">
                        <div className="number_circle bc_blue">
                            4
                        </div>
                        <p className="margin_top_16 fs_18 f_600 text_left">Expert Insights</p>
                        <div className="margin_top_16 text_left desc">
                            Expert Insights: "Benefit from our real estate expertise. We curate listings and provide
                            valuable insights to help you make informed decisions."
                        </div>
                    </div>
                </div>
                <div className="d_flex fd_column justify_content_start text_left why_choose_b2">
                    <h2>Why people choose us</h2>
                    <div className="">
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                        laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
                        beatae vitae dicta sunt explicabo.
                    </div>
                    <div className="margin_top_24">
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
                        consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                        qui dolorem ipsum quia dolor sit amet, consectetur
                    </div>
                    <div className="d_flex justify_content_start view_listings_btn_b">
                        <button className="view_listings_btn">View Listings</button>
                    </div>

                </div>
            </div>
        </div>
    )
}