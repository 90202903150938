import "./style.css";
import footer_logo from "../../assets/images/icons/footer_logo.svg";
import facebook_icon from "../../assets/images/icons/Facebbok_icon.png";
import linkedin_icon from "../../assets/images/icons/linkedin_icon.png";
import twitter_icon from "../../assets/images/icons/twitter_icon.png";
import youtube_icon from "../../assets/images/icons/youtube_icon.png";
import {NavLink} from "react-router-dom";
import tel_icon from "../../assets/images/icons/phone_icon.svg";
import email_icon from "../../assets/images/icons/email_icon.svg";


export default function Footer() {
    return (
        <div className="footer_container">
            <div className="container d_flex fd_column">
                <div className="d_flex space_between">
                    <div className="footer_b_1 d_flex fd_column justify_content_start">
                        <img src={footer_logo} alt="logo" className="logo"/>
                        <div className="d_flex fd_column justify_content_start">
                            <p className="c_white text_left fs_18 f_600">Follow Us</p>
                            <div className="d_flex align_items_center social_blocks">
                                <div>
                                    <a href="https://facebook.com" target="_blank"><img src={facebook_icon}
                                                                                        alt="facebook_icon"/></a>
                                </div>
                                <div>
                                    <a href="https://twitter.com/" target="_blank"><img src={twitter_icon}
                                                                                        alt="twitter_icon"/></a>
                                </div>
                                <div>
                                    <a href="https://linkedin.com" target="_blank"><img src={linkedin_icon}
                                                                                        alt="linkedin_icon"/></a>
                                </div>
                                <div>
                                    <a href="https://youtube.com" target="_blank"><img src={youtube_icon}
                                                                                       alt="youtube_icon"/></a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="footer_b_2 d_flex align_items_start space_between">
                        <div className="d_flex fd_column justify_content_start footer_b_2_item">
                            <h3 className="fs_18 f_600 c_white text_left">Quick Links</h3>
                            <div className="d_flex fd_column justify_content_start">
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_btn_active f_600' : 'footer_btn'}
                                    to="/">Home</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_btn_active f_600' : 'footer_btn'}
                                    to="/search_listings">Search Listings</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_btn_active f_600' : 'footer_btn'}
                                    to="/how_works">How  It Works</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_btn_active f_600' : 'footer_btn'}
                                    to="/contact">Contact Us</NavLink>
                            </div>
                        </div>
                        <div className="d_flex fd_column justify_content_start footer_b_2_item">
                            <h3 className="fs_18 f_600 c_white text_left">Resources</h3>
                            <div className="d_flex fd_column justify_content_start">
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_btn_active f_600' : 'footer_btn'}
                                    to="/policy">Privacy Policy</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_btn_active f_600' : 'footer_btn'}
                                    to="/terms">Terms of Service</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_btn_active f_600' : 'footer_btn'}
                                    to="/how_works">FAQ</NavLink>
                            </div>
                        </div>
                        <div className="d_flex fd_column justify_content_start footer_b_2_item">
                            <h3 className="fs_18 f_600 c_white text_left">Subscribe To our Newsletter</h3>
                            <div className="d_flex fd_column justify_content_start">
                              <div className="email_block d_flex space_between">
                                  <input type="email" placeholder="Enter Your Email"/>
                                  <button>Send</button>
                              </div>
                                <h3 className="fs_18 f_600 c_white text_left">Support Service</h3>
                                <div className="d_flex fd_column justify_content_start">
                                   <div className="d_flex align_items_center tel_icon_b">
                                       <img src={tel_icon} alt="tel_icon"/>
                                       <a href="tel:+1900 12 213 21" className="" target="_blank">+1900 12 213 21</a>
                                   </div>
                                    <div className="d_flex align_items_center tel_icon_b">
                                        <img src={email_icon} alt="email_icon"/>
                                        <a href="mailto:office@rehat.com" className="" target="_blank">office@rehat.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="rights_b fs_14 c_white">
                    © 2023 HOMECHIRP. All Right Reserved
                </div>
            </div>
        </div>
    )
}