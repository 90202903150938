import "./style.css";
import SearchFilter from "../search_filter/SearchFilter";
import SearchResults from "../search_results/SearchResults";

export default function SearchBlock(){
    return (
        <div className="d_flex justify_content_start search_block_cont">
            <SearchFilter/>
            <SearchResults/>
        </div>
    )
}