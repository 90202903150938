import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home/Home";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import Listings from "./pages/Listings/Listings";
import FlatDescription from "./pages/FlatDescription/FlatDescription";


function App() {
  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}/>
              <Route path="/listings" element={<Listings/>}/>
              <Route path="/flat_description/:id" element={<FlatDescription/>}/>
            <Route path="/sign_up" element={<SignUp/>}/>
            <Route path="/sign_in" element={<SignIn/>}/>

            {/*<Route path="/new_verifications" element={<NewVerifications/>}>*/}
            {/*  <Route path="new_verification_requests" element={<NewVerificationRequests/>}/>*/}
            {/*  <Route path="view_certificate_sell" element={<ViewCertificateSell/>}/>*/}
            {/*  <Route index path="view_certificate_license" element={<ViewCertificateLicense/>}/>*/}
            {/*  <Route path="view_certificate_charge" element={<ViewCertificateCharge/>}/>*/}
            {/*  <Route path="agreement_partnership" element={<ViewAgreementPartnerShip/>}/>*/}
            {/*</Route>*/}
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;

