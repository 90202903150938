import "./style.css";
import down_icon from "../../../../assets/images/icons/down_icon.png";
import React, {useEffect, useState} from "react";
import RangeSlider from "../../../Home/components/modal_quiz/components/RangeSlider";

let valueRange = [0, 20]

export default function SearchFilter() {
    let [showHomeType, setShowHomeType] = useState(false)
    let [showHomeValue, setShowHomeValue] = useState(
        [
            {
                id: 1,
                type: "House",
                checked: false
            },
            {
                id: 2,
                type: "Townhome",
                checked: false
            },
            {
                id: 3,
                type: "Multi-family",
                checked: false
            },
            {
                id: 4,
                type: "Lots/Land",
                checked: false
            },

            {
                id: 5,
                type: "Manufactured",
                checked: false
            },
        ]
    )
    let [showBedrooms, setShowBedrooms] = useState(false)
    const [letBedrooms, setLetBedrooms] = useState([
        {
            id: 1,
            type: "1",
            checked: false
        },
        {
            id: 2,
            type: "2",
            checked: false
        },
        {
            id: 3,
            type: "3",
            checked: false
        },
        {
            id: 4,
            type: "4",
            checked: false
        },

        {
            id: 5,
            type: "5+",
            checked: false
        },
    ])

    let [showBathrooms, setShowBathrooms] = useState(false)
    const [letBathrooms, setLetBathrooms] = useState([
        {
            id: 1,
            type: "1",
            checked: false
        },
        {
            id: 2,
            type: "2",
            checked: false
        },
        {
            id: 3,
            type: "3+",
            checked: false
        },
    ])
    let [showAcreage, setShowAcreage] = useState(false)
    let [showPrice, setShowPrice] = useState(false)
    const [acreageValue, setAcreageValue] = useState([0, 20]);
    const [priceValue, setPriceValue] = useState([0, 20]);

    const handleChangeAcreage = (event, newValue) => {
        console.log(newValue);
        setAcreageValue(newValue);
    };

    const handleChange = (event, newValue) => {
        setPriceValue(newValue)
    };

    function valueText(value) {
        return `${value}`;
    }


    useEffect(() => {
        let data = JSON.parse(localStorage.getItem('filterData'))
        if (data.stepOne) {
            let index = showHomeValue.findIndex(e => e.type === data.stepOne)
            if (index !== -1) {
                showHomeValue[index].checked = true
                setShowHomeValue([...showHomeValue])
            }
        }
        if (data.stepTwo) {
            let index = letBedrooms.findIndex(e => e.type === data.stepTwo)
            if (index !== -1) {
                letBedrooms[index].checked = true
                setLetBedrooms([...letBedrooms])
            }
        }
        if (data.stepTree) {
            let index = letBathrooms.findIndex(e => e.type === data.stepTree)
            if (index !== -1) {
                letBathrooms[index].checked = true
                setLetBathrooms([...letBathrooms])
            }
        }
        if (data.stepFour) {
            setAcreageValue(data.stepFour)
        }
        if (data.stepFive) {
            setPriceValue(data.stepFive)
        }
    }, [])



    return (
        <div className="search_filter_cont">
            <div className="d_flex space_between align_items_center">
                <p className="fs_20 f_600 c_darkBlue">Filters</p>
                <button className="reset_filters_btn">Reset filters</button>
            </div>
            <div className="d_flex fd_column search_filter_cont_content">
                <div className="d_flex fd_column">
                    <div className="d_flex align_items_center space_between home_type_b"
                         onClick={() => setShowHomeType(!showHomeType)}>
                        <p className="fs_20 f_600 c_darkBlue">Home Type</p>
                        {
                            showHomeType ?
                                <div>
                                    <img src={down_icon} alt="down_icon" className="down_icon"/>
                                </div>
                                :
                                <div>
                                    <img src={down_icon} alt="top_icon" className="top_icon"/>
                                </div>

                        }
                    </div>
                    {
                        showHomeType ?
                            <div className="d_flex fd_column dropdown_content">
                                {showHomeValue.map((item, index) => {
                                    return (
                                        <div className="d_flex align_items_center  dropdown_content_item" key={item.id}>
                                            <input type="checkbox" className=""
                                                   checked={item.checked}
                                                   onChange={e => {
                                                       for (let i = 0; i < showHomeValue.length; i++) {
                                                           showHomeValue[i].checked = false
                                                       }
                                                       showHomeValue[index].checked = true
                                                       setShowHomeValue([...showHomeValue])
                                                   }}
                                            />
                                            {item.type}
                                        </div>
                                    )
                                })
                                }
                            </div>
                            : null
                    }
                </div>
                <div className="d_flex fd_column margin_top_24">
                    <div className="d_flex align_items_center space_between home_type_b"
                         onClick={() => setShowBedrooms(!showBedrooms)}>
                        <p className="fs_20 f_600 c_darkBlue">Bedrooms</p>
                        {
                            showBedrooms ?
                                <div>
                                    <img src={down_icon} alt="down_icon" className="down_icon"/>
                                </div>
                                :
                                <div>
                                    <img src={down_icon} alt="top_icon" className="top_icon"/>
                                </div>

                        }
                    </div>
                    {
                        showBedrooms ?
                            <div className="d_flex fd_column dropdown_content">
                                {
                                    letBedrooms.map((item, index) => {
                                        return (
                                            <div className="d_flex align_items_center  dropdown_content_item"
                                                 key={item.id}>
                                                <input type="checkbox" className=""
                                                       checked={item.checked}
                                                       onChange={e => {
                                                           for (let i = 0; i < letBedrooms.length; i++) {
                                                               letBedrooms[i].checked = false
                                                           }
                                                           letBedrooms[index].checked = true
                                                           setLetBedrooms([...letBedrooms])
                                                       }}
                                                />
                                                {item.type}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                    }
                </div>
                <div className="d_flex fd_column margin_top_24">
                    <div className="d_flex align_items_center space_between home_type_b"
                         onClick={() => setShowBathrooms(!showBathrooms)}>
                        <p className="fs_20 f_600 c_darkBlue">Bathrooms</p>
                        {
                            showBathrooms ?
                                <div>
                                    <img src={down_icon} alt="down_icon" className="down_icon"/>
                                </div>
                                :
                                <div>
                                    <img src={down_icon} alt="top_icon" className="top_icon"/>
                                </div>

                        }
                    </div>
                    {
                        showBathrooms ?
                            <div className="d_flex fd_column dropdown_content">
                                {
                                    letBathrooms.map((item, index) => {
                                        return (
                                            <div className="d_flex align_items_center  dropdown_content_item"
                                                 key={item.id}>
                                                <input type="checkbox" className=""
                                                       checked={item.checked}
                                                       onChange={e => {
                                                           for (let i = 0; i < letBathrooms.length; i++) {
                                                               letBathrooms[i].checked = false
                                                           }
                                                           letBathrooms[index].checked = true
                                                           setLetBathrooms([...letBathrooms])
                                                       }}
                                                />
                                                {item.type}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                    }
                </div>
                <div className="d_flex fd_column margin_top_24">
                    <div className="d_flex align_items_center space_between home_type_b"
                         onClick={() => setShowAcreage(!showAcreage)}>
                        <p className="fs_20 f_600 c_darkBlue">Acreage</p>
                        {
                            showAcreage ?
                                <div>
                                    <img src={down_icon} alt="down_icon" className="down_icon"/>
                                </div>
                                :
                                <div>
                                    <img src={down_icon} alt="top_icon" className="top_icon"/>
                                </div>

                        }
                    </div>
                    {
                        showAcreage ?
                            <div className="d_flex fd_column quiz_modal_block2_items">
                                <div className="d_flex align_items_center ranger_input_b">
                                    <div className="d_flex align_items_center ">
                                        <input type="text" className="" placeholder="Min sqft."
                                               value={acreageValue[0]}
                                               onChange={e => {
                                                   acreageValue[0] = e.target.value
                                                   setAcreageValue([...acreageValue])
                                               }}
                                        />
                                    </div>
                                    <div className="d_flex align_items_center">
                                        <input type="text" className="" placeholder="Max sqft."
                                               value={acreageValue[1]}
                                               onChange={e => {
                                                   acreageValue[1] = e.target.value
                                                   setAcreageValue([...acreageValue])
                                               }}
                                        />
                                    </div>
                                </div>
                                <RangeSlider
                                    value={acreageValue}
                                    setValue={setAcreageValue}
                                    handleChange={handleChangeAcreage}
                                    valueText={valueText}
                                    valueRange={valueRange}
                                />
                            </div>
                            : null
                    }
                </div>
                <div className={showAcreage ? "d_flex fd_column add_margin" : "d_flex fd_column margin_top_24"}>
                    <div className="d_flex align_items_center space_between home_type_b"
                         onClick={() => setShowPrice(!showPrice)}>
                        <p className="fs_20 f_600 c_darkBlue">Price</p>
                        {
                            showPrice ?
                                <div>
                                    <img src={down_icon} alt="down_icon" className="down_icon"/>
                                </div>
                                :
                                <div>
                                    <img src={down_icon} alt="top_icon" className="top_icon"/>
                                </div>

                        }
                    </div>
                    {
                        showPrice ?
                            <div className="d_flex fd_column quiz_modal_block2_items">
                                <div className="d_flex align_items_center ranger_input_b">
                                    <div className="d_flex align_items_center ">
                                        <input type="text" className="" placeholder="Min $."
                                               value={priceValue[0]}
                                               onChange={e => {
                                                   priceValue[0] = e.target.value
                                                   setPriceValue([...priceValue])
                                               }}
                                        />
                                    </div>
                                    <div className="d_flex align_items_center">
                                        <input type="text" className="" placeholder="Max $."
                                               value={priceValue[1]}
                                               onChange={e => {
                                                   if (e.target.value >= valueRange[0] && e.target.value <= valueRange[1])
                                                       priceValue[1] = e.target.value
                                                   setPriceValue([...priceValue])
                                               }}/>
                                    </div>
                                </div>
                                <RangeSlider
                                    value={priceValue}
                                    setValue={setPriceValue}
                                    handleChange={handleChange}
                                    valueText={valueText}
                                    valueRange={valueRange}
                                />
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}
