import "./style.css";
import sort_icon from "../../../../assets/images/icons/sort_icon.png";
import {useState} from "react";
import slider_pic_1 from "../../../../assets/images/swiper_Image_1.svg";
import slider_pic_2 from "../../../../assets/images/swiper_image_2.svg";
import location_icon from "../../../../assets/images/icons/mdi_location.png";
import beds_icon from "../../../../assets/images/icons/tabler_bed-filled.png";
import surface_icon from "../../../../assets/images/icons/ri_ruler-2-fill.png";
import bath_icon from "../../../../assets/images/icons/solar_bath-bold.png";
import Pagination from "../../../../componets/paginbation/Pagination";
import PaginationComponent from "../../../../componets/paginbation/Pagination";
import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function SearchResults() {
    let navigate = useNavigate()
    let [found, setFound] = useState([
        {
            id: 1,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 2,
            img: slider_pic_2,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 3,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 4,
            img: slider_pic_2,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 5,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 4,
            img: slider_pic_2,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 7,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 8,
            img: slider_pic_2,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 9,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },

    ])
    const [page, setPage] = useState(1);

    const handleChange = (event, value) => {
        setPage(value);
        console.log(page, "gfgfg")
    };

    let handleNavigateDesc = (id) => {
        localStorage.setItem('foundData', JSON.stringify(found[id]))
        navigate(`/flat_description/${id}`)
    }



    return (
        <div className="d_flex fd_column justify_content_center align_items_center">
            <div className="search_results_cont d_flex fd_column">
                <div className="d_flex space_between align_items_center sort_b">
                    <p className="fs_18 f_500">{found.length} Properties Found</p>
                    <div className="d_flex align_items_center">
                        <img src={sort_icon} alt="sort_icon" className="sort_icon"/>
                        Sort by: Relevancy
                    </div>
                </div>
                <div>
                    <Typography>
                        <div className="d_grid grid_gab_32 grid_columns_3fr search_results_content margin_top_24">
                            {
                                found.map((item, index) => {
                                    return (
                                        <div className="search_results_content_item"
                                             onClick={() => handleNavigateDesc(item.id)}>
                                            <img src={item.img} alt="flat_pic" className="flat_pic"/>
                                            <div className="search_results_content_info_b">
                                                <div
                                                    className="d_flex align_items_center space_between search_results_content_info_b1">
                                                    <h3>{item.flatClass}</h3>
                                                    <span>{item.price}</span>
                                                </div>
                                                <div className="d_flex align_items_center location_b margin_top_16">
                                                    <img src={location_icon} alt="location_icon"/>
                                                    {item.location}
                                                </div>
                                                <div
                                                    className="d_flex align_items_center space_between margin_top_24 flat_info_b">
                                                    <div className="d_flex align_items_center">
                                                        <img src={beds_icon} alt="beds_icon"/>
                                                        {item.surface} Beds
                                                    </div>
                                                    <div className="d_flex align_items_center">
                                                        <img src={surface_icon} alt="beds_icon"/>
                                                        {item.bedsCount} Sqft
                                                    </div>
                                                    <div className="d_flex align_items_center">
                                                        <img src={bath_icon} alt="beds_icon"/>
                                                        {item.bathCount} Bath
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Typography>
                </div>
            </div>
            <PaginationComponent found={found} setFound={setFound} page={page} setPage={setPage}
                                 handleChange={handleChange}/>
        </div>
    )
}