import "./style.css";
import {useState} from "react";
import img from "../../../../assets/images/Image.svg";
import img1 from "../../../../assets/images/Image (1).svg";
import img2 from "../../../../assets/images/Image (2).svg";
import img3 from "../../../../assets/images/Image (3).svg";
import img4 from "../../../../assets/images/Image (4).svg";
import img5 from "../../../../assets/images/Image (5).svg";


export default function Categories() {
    let[categories,setCategories]=useState([
        {
            id:1,
            img:img,
            text:"Houses",
        },
        {
            id:2,
            img:img1,
            text:"Townhomes",
        },
        {
            id:1,
            img:img2,
            text:"Multi-family",
        },
        {
            id:1,
            img:img3,
            text:"Condos/Co-ops",
        },
        {
            id:1,
            img:img4,
            text:"Lots/Land",
        },
        {
            id:1,
            img:img5,
            text:"Manufactured",
        },
    ])
    return (
        <div className="container">
            <div className="categories_container margin_top_160">
                <h2 className="text_center">Categories</h2>
                <div className="title_2 d_flex justify_content_center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua.
                </div>
                <div className="d_grid grid_columns_3fr grid_gab_32 categories_cont">

                        {
                            categories.map((item,index)=>{
                                return (
                                    <div className="categories_cont_item" key={item.id}>
                                        <img src={item.img} alt="img"/>
                                        <p>{item.text}</p>
                                    </div>
                                )
                            })
                        }


                </div>
                <div className="d_flex justify_content_center align_items_center view_all_btn_b">
                    <button className="view_all_btn fs_18 f_600">View All</button>
                </div>
            </div>
        </div>
    )
}