import "./style.css";
import Footer from "../../componets/footer/footer";
import Header from "../../componets/header/header";
import address_icon from "../../assets/images/icons/mdi_location.png";
import fullName_icon from "../../assets/images/icons/CA_fullName_icon.png";
import phone_number_icon from "../../assets/images/icons/CA_phone_icon1.svg.png";
import message_icon from "../../assets/images/icons/CA_Chat_Circle_icon.png";
import CA_agent_pic from "../../assets/images/contact_agent_pic.png";
import FlatRangeSlider from "./components/flat_range_slider/FlatRangeSlider";
import {useState} from "react";
import beds_icon from "../../assets/images/icons/tabler_bed-filled.png";
import surface_icon from "../../assets/images/icons/ri_ruler-2-fill.png";
import bath_icon from "../../assets/images/icons/solar_bath-bold.png";
import DetailsAccordion from "./components/details_accordion/DetailsAccordion";
import ContactAgent from "./components/contact_agent/ContactAgent";
import RelatedProperties from "./components/related_properties/RelatedProperties";


export default function FlatDescription(props) {
    let flatDescData = JSON.parse(localStorage.getItem('foundData'))
console.log(flatDescData,"flatDescData")
    return (
        <div className="main">
            <div>
                <Header/>
                <div className="container">
                    <div className="flat_desc_cont d_flex fd_column">
                        <h2 className="text_left title">{flatDescData.flatClass}</h2>
                        <p className="text_left d_flex align_items_center desc margin_top_16 title_address fs_18">
                            <img src={address_icon} alt=""/>{flatDescData.location}</p>
                        <div className="d_flex space_between margin_top_48">
                            <div className="d_flex fd_column justify_content_start flat_desc_cont_b1">
                                <FlatRangeSlider img={flatDescData.img}/>
                                <div className="flat_desc_cont_b1_content">
                                    <h2 className="fs_24 text_left">Highlights</h2>
                                    <div className="d_flex highlights_b margin_top_24">
                                        <div
                                            className="d_flex fd_column justify_content_start align_items_start highlights_b_1">
                                            <div className="margin_top_24 fs_16"><img src={beds_icon} alt="bed_icon"/>
                                                {flatDescData.bedsCount} Beds
                                            </div>
                                            <div className="margin_top_24 fs_16"><img src={bath_icon} alt="bed_icon"/>
                                                {flatDescData.bathCount} Bath
                                            </div>
                                            <div className="margin_top_24 fs_16"><img src={surface_icon}
                                                                                      alt="bed_icon"/>300 Sqft
                                            </div>
                                        </div>
                                        <div
                                            className="d_flex fd_column justify_content_start align_items_start highlights_b_2">
                                            <div className="margin_top_24 fs_16"><img src={beds_icon} alt="bed_icon"/>Garden
                                            </div>
                                            <div className="margin_top_24 fs_16"><img src={beds_icon} alt="bed_icon"/>Play
                                                Area
                                            </div>
                                            <div className="margin_top_24 fs_16"><img src={beds_icon} alt="bed_icon"/>Swimming
                                                Pool
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="fs_24 text_left">Description</h2>
                                    <div className="description_b d_flex fd_column align_items_start">
                                        <div className="margin_top_24 text_left fs_16">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.
                                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                            dolore.
                                        </div>
                                        <div className="margin_top_24 text_left fs_16">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.
                                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
                                            eu
                                            fugiat.
                                        </div>
                                    </div>
                                    <h2 className="fs_24 text_left">Details</h2>
                                    <div className="details_b d_flex fd_column align_items_start">
                                        <DetailsAccordion/>
                                    </div>
                                </div>
                            </div>
                            <ContactAgent/>
                        </div>
                        <RelatedProperties/>
                    </div>
                </div>
            </div>

            <div>
                <Footer/>
            </div>
        </div>
    )
}