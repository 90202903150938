import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import "./style.css";
import HomeType from "./components/home_type/HomeType";
import Bedrooms from "./components/bedrooms/Bedrooms";
import Bathrooms from "./components/bathrooms/Bathrooms";
import Price from "./components/price/Price";
import Town from "./components/town/Town";
import Information from "./components/Information/Information";
import Acreage from "./components/acreage/Acreage";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default function ModalQuiz({modalQuizOpen, setModalQuizOpen, openQuizModal, closeQuizModal}) {
    const [activeStep, setActiveStep] = useState(0);
    const [data, setData] = useState({});

    let changeStep = (type) => {
        if(type==='next'){
            setActiveStep(activeStep + 1)
        }
        if(type === 'back'){
            setActiveStep(activeStep - 1)
        }
    }
    console.log(data);
    let currentStep = () => {
        switch (activeStep) {
            case 0:
                return <HomeType changeStep={changeStep} data={data} setData={setData}/>
            case 1:
                return  <Bedrooms changeStep={changeStep}  data={data} setData={setData}/>;
            case 2:
                return  <Bathrooms changeStep={changeStep}  data={data} setData={setData}/>;
            case 3:
                return  <Acreage changeStep={changeStep}  data={data} setData={setData}/>;
            case 4:
                return  <Price changeStep={changeStep}  data={data} setData={setData}/>;
            case 5:
                return  <Town changeStep={changeStep}  data={data} setData={setData}/>;
            case 6:
                return  <Information changeStep={changeStep}  data={data} setData={setData} closeQuizModal={closeQuizModal}/>;
        }
    }

    return (
        <div>
            <Modal
                isOpen={modalQuizOpen}
                onRequestClose={closeQuizModal}
                style={customStyles}
                contentLabel="Example Modal"
                className="quiz_modal"
            >
                <div className="modal_content d_flex">
                    <button onClick={closeQuizModal} className="close_modal">x</button>
                    {currentStep()}
                </div>
            </Modal>
        </div>
    );
}