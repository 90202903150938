import "./style.css";
import arrow_1 from "../../../../assets/images/arrow_1.svg";
import arrow_2 from "../../../../assets/images/arrow_2.svg";
import search_icon from "../../../../assets/images/icons/search_icon.svg";
import home_icon from "../../../../assets/images/icons/home_icon.svg";
import notification_icon from "../../../../assets/images/icons/notification_icon.svg";

export default function HowWorks() {
    return (
        <div className="container">
            <div className="how_works_container margin_top_160">
                <h2 className="text_center">How it works?</h2>
                <div className="title_2 d_flex justify_content_center">Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua.
                </div>
                <div className="d_flex how_works_container_content space_between">
                    <div
                        className="d_flex fd_column align_items_center justify_content_center  how_works_container_content_item">
                        <img src={search_icon} alt="search_icon"/>
                        <p className="margin_top_32">Tell Us What You're
                            Looking For</p>
                        <div className="text_center margin_top_24">Start by entering your preferred zip code, price
                            range, house size, and other criteria in the search bar on our home page.
                        </div>
                    </div>
                    <div className="d_flex align_items_center arrow_b" >
                        <img src={arrow_2} alt="arrow"/>
                    </div>
                    <div
                        className="d_flex fd_column align_items_center justify_content_center  how_works_container_content_item">
                        <img src={home_icon} alt="home_icon"/>
                        <p className="margin_top_32">We Fetch
                            the Latest Listings</p>
                        <div className="text_center margin_top_24">Your Dream Home Finder immediately finds house
                            listings that match your specific criteria.
                        </div>
                    </div>
                    <div className="d_flex align_items_center arrow_b">
                        <img src={arrow_1} alt="arrow"/>
                    </div>
                    <div
                        className="d_flex fd_column align_items_center justify_content_center  how_works_container_content_item">
                        <img src={notification_icon} alt="notification_icon"/>
                        <p className="margin_top_32"> Daily Email or Text Notifications</p>
                        <div className="text_center margin_top_24">We'll send you a tailored list of new house listings
                            right to your inbox or phone, keeping you updated with the latest properties.
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
