import "../../style.css";
import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";

export default function HomeType({changeStep, data, setData}) {
    const [letHomeType, setHomeType]=useState([
        {
            id:1,
            type:"House",
        },
        {
            id:2,
            type:"Townhome",
        },
        {
            id:3,
            type:"Multi-family",
        },
        {
            id:4,
            type:"Lots/Land",
        },

        {
            id:5,
            type:"Manufactured",
        },
    ])

    const addValue = useCallback(type => {
        data.stepOne = type
        setData({...data})
    }, [data])




    return (
        <div className="d_flex quiz_modal_block ">
            <div className="d_flex fd_column quiz_modal_block1">
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">1</span>
                    Home Type
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">2</span>
                    Bedrooms
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">3</span>
                    Bathrooms
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">4</span>
                    Acreage
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">5</span>
                    Price
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">6</span>
                    Town
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">7</span>
                    Your Information
                </div>
            </div>
            <div className="d_flex fd_column space_between quiz_modal_block2">
                <div>
                    <h2 className="f_600">Select Home Type</h2>
                    <div className="d_flex fd_column quiz_modal_block2_items">
                        {
                            letHomeType.map((item,index)=>{
                                return (
                                    <div className="d_flex align_items_center quiz_modal_block2_item margin_top_24"  key={item.id}>
                                        <input type="checkbox" className="" onChange={(e)=>addValue(item.type)}/>
                                        {item.type}
                                    </div>
                                )
                            })
                        }
                        {/*<div className="d_flex align_items_center quiz_modal_block2_item">*/}
                        {/*    <input type="checkbox" className="" onChange={handleChangeInput}/>*/}
                        {/*    House*/}
                        {/*</div>*/}
                        {/*<div className="d_flex align_items_center quiz_modal_block2_item margin_top_24">*/}
                        {/*    <input type="checkbox"/>*/}
                        {/*    Townhome*/}
                        {/*</div>*/}
                        {/*<div className="d_flex align_items_center quiz_modal_block2_item margin_top_24">*/}
                        {/*    <input type="checkbox"/>*/}
                        {/*    Multi-family*/}
                        {/*</div>*/}
                        {/*<div className="d_flex align_items_center quiz_modal_block2_item margin_top_24">*/}
                        {/*    <input type="checkbox"/>*/}
                        {/*    Lots/Land*/}
                        {/*</div>*/}
                        {/*<div className="d_flex align_items_center quiz_modal_block2_item margin_top_24">*/}
                        {/*    <input type="checkbox"/>*/}
                        {/*    Manufactured*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="d_flex justify_content_start align_items_center next_b" onClick={() =>changeStep('next')}>
                    <button className="c_white bc_blue fs_18 f_600">Next <span> > </span></button>
                </div>
            </div>
        </div>
    )
}