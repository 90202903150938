import "./style.css";
import sign_pic from "../../assets/images/SIGN_PIC.png";
import show_icon from "../../assets/images/icons/show_icon.png";
import hidden_icon from "../../assets/images/icons/hidden_icon.png";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import logo from "../../assets/images/logo.svg";

export default function SignIn() {
    let navigate = useNavigate()
    let [showPass, setShowPass] = useState(false)

    let SignInHandler = () => {
        // localStorage.setItem('jwtToken',response.data.token)
        navigate("/")
    }

    return (
        <div className="sign_container d_flex align_items_center">
            <div className="sign_container_b1 d_flex justify_content_center">
                <div className="d_flex fd_column sign_container_b1_inner">
                    <h2 className="text_left fs_40 f_600">Sign In</h2>
                    <div className="text_left margin_top_16 sign_text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.
                    </div>
                    <div className="d_flex fd_column justify_content_start inputs_block">
                        <div className="d_flex fd_column justify_content_start input_block">
                            <label htmlFor="" className="fs_18 f_500 text_left">Email</label>
                            <input type="Email" placeholder="example@mail.com"/>
                        </div>

                        <div className="d_flex fd_column justify_content_start input_block password_b margin_top_32">
                            <label htmlFor="" className="fs_18 f_500 text_left">Password</label>
                            <input type={showPass ? "text" : "password"} placeholder="******"/>
                            {showPass ?
                                <img src={show_icon} alt="show" className="show_hidden_icon"
                                     onClick={() => setShowPass(!showPass)}/>
                                :
                                <img src={hidden_icon} alt="hidden" className="show_hidden_icon"
                                     onClick={() => setShowPass(!showPass)}/>
                            }
                        </div>
                        <div className="d_flex justify_content_end forgot_pass margin_top_12 fs_16 f_500"
                             onClick={() => navigate("")}>
                            Forgot password?
                        </div>
                    </div>
                    <div className="sign_btn" onClick={SignInHandler}>
                        <button className="c_white fs_18 f_600">Sign in</button>
                    </div>
                    <div className="have_account fs_18 f_500">Don’t have an account yet? <span
                        onClick={() => navigate("/sign_up")}>Sign up</span></div>
                    <div className="d_flex justify_content_center margin_top_32" onClick={() => navigate("/")}>
                        <img src={logo} alt="logo"/>
                    </div>
                </div>

            </div>
            <div className="sign_container_b2">
                <img src={sign_pic} alt="pic" className="sign_pic"/>
            </div>

        </div>
    )
}