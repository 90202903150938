import "./style.css";
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import {Pagination, Navigation} from 'swiper/modules';
import {useState} from "react";
import slider_pic_1 from "../../../../assets/images/swiper_Image_1.svg";
import slider_pic_2 from "../../../../assets/images/swiper_image_2.svg";
import location_icon from "../../../../assets/images/icons/mdi_location.png";
import beds_icon from "../../../../assets/images/icons/tabler_bed-filled.png"
import surface_icon from "../../../../assets/images/icons/ri_ruler-2-fill.png";
import bath_icon from "../../../../assets/images/icons/solar_bath-bold.png";
import next_icon from "../../../../assets/images/icons/next slide.svg"
import previous_icon from "../../../../assets/images/icons/previous slide.svg"

export default function ExploreSlider() {
    let [slide, setSlide] = useState([
        {
            id: 1,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 2,
            img: slider_pic_2,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 3,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 4,
            img: slider_pic_2,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 3,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 4,
            img: slider_pic_2,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 5,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 6,
            img: slider_pic_2,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 7,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },

    ])
    return (
        <div className="container explore_swiper_container">
            <Swiper
                slidesPerView={4}
                spaceBetween={30}
                loop={true}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                breakpoints={{
                    // when window width is >= 640px
                    280: {
                        slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    540: {
                        slidesPerView: 2,
                    },
                   900:{
                        slidesPerView: 3,
                    },
                    1380:{
                        slidesPerView: 4,
                    }
                }}
            >

                <div className="d_flex fd_column explorer_swiper_slide">
                    {
                        slide.map((item, index) => {
                            return (
                                <SwiperSlide>
                                    <img src={item.img} alt="flat_pic" className="flat_pic"/>
                                    <div className="explorer_swiper_slide_info_b">
                                        <div className="d_flex align_items_center space_between explorer_swiper_slide_info_b1">
                                            <h3>{item.flatClass}</h3>
                                            <span>{item.price}</span>
                                        </div>
                                        <div className="d_flex align_items_center location_b margin_top_16">
                                            <img src={location_icon} alt="location_icon"/>
                                            {item.location}
                                        </div>
                                        <div className="d_flex align_items_center space_between margin_top_24 flat_info_b">
                                            <div className="d_flex align_items_center">
                                                <img src={beds_icon} alt="beds_icon"/>
                                                {item.surface} Beds
                                            </div>
                                            <div className="d_flex align_items_center">
                                                <img src={surface_icon} alt="beds_icon"/>
                                                {item.bedsCount} Sqft
                                            </div>
                                            <div className="d_flex align_items_center">
                                                <img src={bath_icon} alt="beds_icon"/>
                                                {item.bathCount} Bath
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </div>

            </Swiper>
        </div>
    )
}