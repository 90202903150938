import "../../style.css";
import React, {useCallback, useRef, useState} from "react";
import step_prev from "../../../../../../assets/images/icons/step_previous_icon.png";

export default function Town({changeStep, data, setData}) {
    let [preferred_town, setPreferredTown] = useState([{}])

    let AddOption = () => {
        preferred_town.push({})
        console.log(preferred_town);
        setPreferredTown([...preferred_town])

    }
    const addValue = useCallback(address => {
        data.stepSix = address
        setData({...data})
    }, [data])

    let handleChangeTown = (e, index) => {
        preferred_town[index].value = e
        setPreferredTown([...preferred_town])
       addValue(preferred_town)
    }
    console.log(preferred_town);

    return (
        <div className="d_flex quiz_modal_block ">
            <div className="d_flex fd_column quiz_modal_block1">
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">1</span>
                    Home Type
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18  active">2</span>
                    Bedrooms
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">3</span>
                    Bathrooms
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">4</span>
                    Acreage
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">5</span>
                    Price
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">6</span>
                    Town
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">7</span>
                    Your Information
                </div>
            </div>
            <div className="d_flex fd_column space_between quiz_modal_block2">
                <div>
                    <h2 className="f_600">Preferred Town</h2>
                    <div className="d_flex fd_column quiz_modal_block2_items">
                        <div className="d_flex fd_column align_items_center quiz_modal_block2_item information_input_b">
                            {preferred_town.map((item, index) => {
                                return (
                                    <input type="text" className="information_input margin_top_24" key={index}
                                           placeholder="Enter state, city, town, zip code"
                                           onChange={(e) => handleChangeTown(e.target.value, index)}/>
                                )
                            })}
                        </div>
                    </div>
                    <div className="d_flex align_items_center quiz_modal_block2_item margin_top_24" onClick={AddOption}>
                        <button className="c_blue fs_16">+ Add another option</button>
                    </div>
                </div>
                <div className="d_flex next_b align_items_center">
                    <div className="previous_b d_flex align_items_center" onClick={() => changeStep('back')}>
                        <img src={step_prev} alt="prev"/>
                    </div>
                    <div className="d_flex justify_content_start align_items_center "
                         onClick={() => changeStep('next')}>
                        <button className="c_white bc_blue fs_18 f_600">Next <span> > </span></button>
                    </div>
                </div>

            </div>
        </div>
    )
}