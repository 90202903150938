import React, {useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import "./style.css";
import {FreeMode, Navigation, Thumbs} from 'swiper/modules';
import flat_slide_pic from "../../../../assets/images/flat_aslide_pic.png";
import flat_pic_2 from "../../../../assets/images/Image (2).svg";


export default function FlatRangeSlider() {
    const thumbsLargeSwiper = useRef(null);
    const thumbsSmallSwiper = useRef(null)
     const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [slidePic, setSlidePic] = useState([
        {
            id: 1,
            pic: flat_slide_pic,
        },
        {
            id: 2,
            pic: flat_slide_pic,
        },
        {
            id: 3,
            pic: flat_pic_2,
        },
        {
            id: 4,
            pic: flat_slide_pic,
        },
        {
            id: 5,
            pic: flat_slide_pic,
        },
        {
            id: 6,
            pic: flat_slide_pic,
        },
        {
            id: 7,
            pic: flat_slide_pic,
        },
        {
            id: 8,
            pic: flat_slide_pic,
        },
        {
            id: 9,
            pic: flat_slide_pic,
        },
        {
            id: 10,
            pic: flat_slide_pic,
        },
    ])
    console.log("tgftgfg")

    return (
        <>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiperLarge"
                ref={thumbsLargeSwiper}
            >
                {
                    slidePic.map((item, index) => {
                        return (
                            <SwiperSlide key={item.id}>
                                <img src={item.pic} className="" alt="flat_pic"/>
                            </SwiperSlide>
                        )
                    })

                }
            </Swiper>
            <Swiper
                spaceBetween={6}
                slidesPerView={8}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiperSmall"
                // onSwiper={setThumbsSwiper}
                ref={thumbsSmallSwiper}
                slideToClickedSlide={true}
                autoScrollOffset={2}
            >
                {
                    slidePic.map((item, index) => {
                        return (
                            <SwiperSlide key={item.id}>
                                <img src={item.pic} className="" alt="flat_pic"/>
                            </SwiperSlide>
                        )
                    })

                }
            </Swiper>
        </>
    )
        ;
}
