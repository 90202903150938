import "./style.css";
import {useState} from "react";
import slider_pic_1 from "../../../../assets/images/swiper_Image_1.svg";
import slider_pic_2 from "../../../../assets/images/swiper_image_2.svg";
import location_icon from "../../../../assets/images/icons/mdi_location.png";
import beds_icon from "../../../../assets/images/icons/tabler_bed-filled.png";
import surface_icon from "../../../../assets/images/icons/ri_ruler-2-fill.png";
import bath_icon from "../../../../assets/images/icons/solar_bath-bold.png";
import {useNavigate} from "react-router-dom";

export default function RelatedProperties() {
    let navigate=useNavigate()
    let [found, setFound] = useState([
        {
            id: 1,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 2,
            img: slider_pic_2,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 3,
            img: slider_pic_1,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
        {
            id: 4,
            img: slider_pic_2,
            flatClass: "Deluxe Flat",
            price: "$599,000",
            location: "123 Elm Street, Tranquilville, CA",
            bedsCount: "4",
            surface: "300",
            bathCount: "3",
        },
    ])

    return (
        <div className="related_properties_cont">
            <h2 className="text_center">Related Properties</h2>
            <p className="text_center margin_top_16">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua.</p>
            <div className="d_grid grid_gab_32 grid_columns_4fr related_properties_content">
                {
                    found.map((item, index) => {
                        return (
                            <div className="search_results_content_item" onClick={()=>navigate(``)}>
                                <img src={item.img} alt="flat_pic" className="flat_pic"/>
                                <div className="search_results_content_info_b">
                                    <div
                                        className="d_flex align_items_center space_between search_results_content_info_b1">
                                        <h3>{item.flatClass}</h3>
                                        <span>{item.price}</span>
                                    </div>
                                    <div className="d_flex align_items_center location_b margin_top_16">
                                        <img src={location_icon} alt="location_icon"/>
                                        {item.location}
                                    </div>
                                    <div
                                        className="d_flex align_items_center space_between margin_top_24 flat_info_b">
                                        <div className="d_flex align_items_center">
                                            <img src={beds_icon} alt="beds_icon"/>
                                            {item.surface} Beds
                                        </div>
                                        <div className="d_flex align_items_center">
                                            <img src={surface_icon} alt="beds_icon"/>
                                            {item.bedsCount} Sqft
                                        </div>
                                        <div className="d_flex align_items_center">
                                            <img src={bath_icon} alt="beds_icon"/>
                                            {item.bathCount} Bath
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}