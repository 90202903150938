import "../../style.css";
import React, {useCallback, useState} from "react";
import step_prev from "../../../../../../assets/images/icons/step_previous_icon.png";

export default function Bathrooms({changeStep,data,setData}){
    const [letBathrooms]=useState([
        {
            id:1,
            type:"1",
        },
        {
            id:2,
            type:"2",
        },
        {
            id:3,
            type:"3+",
        },
    ])

    const addValue = useCallback(type => {
        data.stepTree = type
        setData({...data})
    }, [data])


    return(
        <div className="d_flex quiz_modal_block ">
            <div className="d_flex fd_column quiz_modal_block1">
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">1</span>
                    Home Type
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18  active">2</span>
                    Bedrooms
                </div>
                <div className="line">
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item">
                    <span className="d_flex justify_content_center align_items_center fs_18 active">3</span>
                    Bathrooms
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">4</span>
                    Acreage
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">5</span>
                    Price
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">6</span>
                    Town
                </div>
                <div className="d_flex  align_items_center quiz_modal_block1_item margin_top_24">
                    <span className="d_flex justify_content_center align_items_center fs_18">7</span>
                    Your Information
                </div>
            </div>
            <div className="d_flex fd_column space_between quiz_modal_block2">
                <div>
                    <h2 className="f_600">Select Number of Bathrooms</h2>
                    <div className="d_flex fd_column quiz_modal_block2_items">
                        {letBathrooms.map((item,index)=>{
                            return (
                                <div className="d_flex align_items_center quiz_modal_block2_item margin_top_24" key={item.id}>
                                <input type="checkbox" className="" onChange={(e)=>addValue(item.type)}/>
                                    {item.type}
                            </div>
                            )
                        })
                        }
                    </div>
                </div>
                <div className="d_flex next_b align_items_center">
                    <div className="previous_b d_flex align_items_center" onClick={() => changeStep('back')}>
                        <img src={step_prev} alt="prev"/>
                    </div>
                    <div className="d_flex justify_content_start align_items_center " onClick={() => changeStep('next')}>
                        <button className="c_white bc_blue fs_18 f_600">Next <span> > </span></button>
                    </div>
                </div>

            </div>
        </div>
    )
}